<template>
  <body-card>
    <template>
      <b-overlay :show="loading">
        <!-- <b-row>
          <b-col>
            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
              <div class="titleDiv">
              </div>
            </list-report-head>
          </b-col>
        </b-row> -->
        <div class="text-right">
                  <b-button variant="primary" @click="pdfExport(item.id)" class="btn btn-sm mb-2">
                    <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                  </b-button>
          </div>
        <b-col lg="12">
          <b-row>
            <div class="card card w-100 text-center">
              <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                  style="color: black!important;font-size: 20px!important;"> {{ $t('elearning_config.guest_trainer')+ ' ' +
                  this.$t('globalTrans.details') }}</h5>
              </div>
            </div>
          </b-row>
          <b-row>
            <b-col lg="12" sm="12" class="p-0">
              <b-table-simple bordered striped>
                <b-thead>
                  <b-tr>
                    <b-th style="width:40%" colspan="2">
                      <div class="text-left ml-3 my-2"><img :src="trainingElearningServiceBaseUrl + 'storage/' + profile.image"
                          style="width: 100px; height: 100px; border-radius: 0px !important;" class="center"></div>
                    </b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-th style="" class="w-50 line-height-five" scope="row">{{ $t('globalTrans.name') }}</b-th>
                    <b-td style="" class="w-50 line-height-five">{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name
                      }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th style="" scope="row">{{ $t('globalTrans.mobile') }}</b-th>
                    <b-td style="">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping: false })
                      }}</b-td>
                  </b-tr>
                  <b-tr>
                    <b-th style=" " scope="row">{{ $t('externalTraining.email') }}</b-th>
                    <b-td style=" ">
                      <slot v-if="profile.email">
                        {{ ($i18n.locale=='bn') ? profile.email : profile.email }}
                      </slot>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th style="" scope="col">{{ $t('globalTrans.organization') }}</b-th>
                    <b-td style="" scope="col">
                      <slot v-if="profile.professional_info.org_type_id === 3">
                        {{ ($i18n.locale=='bn') ? profile.professional_info.org_name_bn :
                        profile.professional_info.org_name_en }}
                      </slot>
                      <slot v-else>
                        {{ ($i18n.locale=='bn') ? profile.org_name_bn : profile.org_name_en }}
                      </slot>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-th style="" class="w-50" scope="row">{{ $t('externalTraining.designation') }}</b-th>
                    <b-td style="" class="w-50">
                      {{ ($i18n.locale=='bn') ? profile.professional_info.designation_bn :
                      profile.professional_info.designation_en }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </b-col>
      </b-overlay>
    </template>
  </body-card>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
// import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import { guestTrainerShowData } from '../../api/routes'

export default {
  components: {
    // ListReportHead
  },
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.profile = this.item
  },
  data () {
    return {
      profile: [],
      slOffset: 1,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    getOrganization (id) {
      if (this.profile.professional_info.org_type_id === 3) {
        return this.$i18n.locale === 'bn' ? this.profile.professional_info.org_name_bn : this.profile.professional_info.org_name_en
      } else {
        const organization = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(item => item.value === parseInt(id))
        if (typeof organization !== 'undefined') {
          return this.$i18n.locale === 'en' ? organization.text_en : organization.text_bn
        } else {
          return ''
        }
      }
    },
    async pdfExport (id) {
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 10 })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getPdfData(trainingElearningServiceBaseUrl, guestTrainerShowData + '/' + id, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>

<style>
.hidden_header {
  display: none
}

.card-border {
  border: 1px solid #b9bdc1;
  box-shadow: 1px 1px 6px -1px grey;
  background-color: #dee2e6;
}

[dir=ltr][mode=light] .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
}

.reportTitle {
  font-weight: bold;
  border: 1px solid;
  padding: 8px 15px 8px 15px;
  border-radius: 11px;
}

.titleDiv {
  margin-top: 2rem;
  font-size: 18px;
  margin-bottom: 20px;
}

.report-name {
  font-weight: bold !important;
  text-transform: uppercase;
}

.my-btn {
  padding: 2px !important;
}</style>
