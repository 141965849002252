<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('elearning_iabm.nominated_organization_payment') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{ $t('elearning_config.fiscal_year') }}
              </template>
            <b-form-select
            plain
                id="fiscal_year_id"
                :options="fiscalYearList"
                v-model="search.fiscal_year_id"
                >
            <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-group
              label-for="nominated_org_id"
            >
              <template v-slot:label>
                {{ $t('elearning_iabm.nominated_organization') }}
              </template>
            <b-form-select
            plain
                id="nominated_org_id"
                :options="orgList"
                v-model="search.nominated_org_id"
                >
            <template v-slot:first>
                <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
            </template>
            </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('elearning_iabm.nominated_organization_payment') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template>
      <template v-slot:body>
        <select-column-check :labelData="labelData" :search="search" :columns="columns" />
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                <template v-slot:cell(serial)="data">
                  {{ $n(data.item.serial + pagination.slOffset) }}
                </template>
                <template v-slot:cell(attachment)="data">
                  <a v-if="data.item.attachment" target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/iab/nominated-org-payment/' + data.item.attachment" download><i class="ri-file-download-line"></i>{{$t('globalTrans.view_download')}}</a>
                </template>
                <template v-slot:cell(status)="data">
                    <span class="badge badge-success" v-if="data.item.status === 1">{{$t('globalTrans.active')}}</span>
                    <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                </template>
                <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-form variant="btn-edit" size="sm" @click="edit(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>
                    <b-button :variant="data.item.status === 2 ? ' btn-danger' : ' btn-success'" size="sm" class="action-btn status" :title="data.item.status === 2 ? $t('globalTrans.inactive') : $t('globalTrans.active')" @click="remove(data.item)">
                    <i :class="data.item.status === 2  ? 'ri-toggle-line' : 'ri-toggle-fill'"></i>
                    </b-button>
                </template>
              </b-table>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                v-model="pagination.currentPage"
                :perPage="search.limit"
                :total-rows="pagination.totalRows"
                @input="searchData"
                />
              </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="lg" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="editItemId" :key="editItemId"/>
    </b-modal>
    <b-modal hide-footer="true" id="details" size="lg" :title="$t('elearning_iabm.nominated_organization_payment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
         <!-- <Details :item="item" :key="id"/> -->
         <Details :item="items" :key="viewitemId"/>
    </b-modal>
  </div>
</template>
<script>
import Form from './Form.vue'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { nominatedOrgPaymentList, nominatedOrgPaymentToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Details from './Details'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Form, Details
  },
  data () {
    return {
      sortBy: '',
      sortDirection: '',
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      sortDesc: '',
      search: {
        nominated_org_id: 0,
        fiscal_year_id: 0,
        limit: 20
      },
      viewitemId: 0,
      editItemId: 0,
      items: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '5%' } },
        { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '20%' } },
        { labels: 'elearning_iabm.nominated_organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'elearning_iabm.payment_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'elearning_iabm.amount', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'globalTrans.attachment', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '5%' } },
        { labels: 'globalTrans.action', class: 'text-left', show: '1', order: 5, thStyle: { width: '10%' } }
      ],
      trainingTypeList: [],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    },
    'search.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.trainingTypeList = this.getTrainingTypeList(newVal)
      } else {
        this.trainingTypeList = []
      }
    }
  },
  computed: {
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'fiscalYear_bn' },
          { key: 'circular_memo_no' },
          { key: 'nominatedOrg_bn' },
          { key: 'paymentTyp_bn' },
          { key: 'amount' },
          { key: 'attachment' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'fiscalYear_en' },
          { key: 'circular_memo_no' },
          { key: 'nominatedOrg_en' },
          { key: 'paymentTyp_en' },
          { key: 'amount' },
          { key: 'attachment' },
          { key: 'status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.editItemId === 0) ? this.$t('elearning_iabm.nominated_organization_payment') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_iabm.nominated_organization_payment') + ' ' + this.$t('globalTrans.modify')
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    orgList () {
      return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1)
    },
    paymentTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'ক্যাশ' : 'Cash',
          text_en: 'Cash',
          text_bn: 'ক্যাশ'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'চেক' : 'Check',
          text_en: 'Check',
          text_bn: 'চেক'
        }
      ]
    }
  },
  methods: {
    getTrainingTypeList (id) {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === id)
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      } else if (type === 2) {
        this.changeStatus(12, trainingElearningServiceBaseUrl, nominatedOrgPaymentToggleStatus, item)
      } else if (type === 3) {
        this.details(item)
      }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, nominatedOrgPaymentList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(data => data.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscalYear_en = fiscalYearObj.text_en
            fiscalYearData.fiscalYear_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscalYear_en = ''
            fiscalYearData.fiscalYear_bn = ''
          }
          const nominatedOrgObj = this.$store.state.TrainingElearning.commonObj.nominatedOrgList.find(data => data.value === parseInt(item.nominated_org_id))
          const nominatedOrgData = {}
          if (typeof nominatedOrgObj !== 'undefined') {
            nominatedOrgData.nominatedOrg_en = nominatedOrgObj.text_en
            nominatedOrgData.nominatedOrg_bn = nominatedOrgObj.text_bn
          } else {
            nominatedOrgData.nominatedOrg_en = ''
            nominatedOrgData.nominatedOrg_bn = ''
          }
          const paymentTypeObj = this.paymentTypeList.find(data => data.value === parseInt(item.payment_type_id))
          const paymentTypeData = {}
          if (typeof paymentTypeObj !== 'undefined') {
            paymentTypeData.paymentTyp_en = paymentTypeObj.text_en
            paymentTypeData.paymentTyp_bn = paymentTypeObj.text_bn
          } else {
            paymentTypeData.paymentTyp_en = ''
            paymentTypeData.paymentTyp_bn = ''
          }
          return Object.assign({}, item, { serial: index }, fiscalYearData, nominatedOrgData, paymentTypeData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
