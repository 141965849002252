<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="fiscal_year_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="formData.fiscal_year_id"
                    :options="fiscalYearList"
                    id="fiscal_year_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
              <b-form-group
                  class="row"
                 label-cols-sm="12"
                  label-for="circular_memo_no"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="formData.circular_memo_no"
                    :options="circularList"
                    id="circular_memo_no"
                    :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                 label-cols-sm="12"
                  label-for="org_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="formData.org_id"
                    :options="orgList"
                    id="org_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                    disabled
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                 label-cols-sm="12"
                  label-for="training_type_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="formData.training_type_id"
                  :options="trainingTypeList"
                  id="training_type_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  disabled
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
              <b-form-group
                class="row"
               label-cols-sm="12"
                label-for="training_title_id"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  v-model="formData.training_title_id"
                  :options="trainingTitleList"
                  id="training_title_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  disabled
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Nominated Organization Type"  vid="org_type_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="org_type_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.nominated_organization_type')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="formData.org_type_id"
                    :options="orgTypeList"
                    id="org_type_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col v-if="formData.org_type_id === 2" xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Nominated Organization"  vid="nominated_org_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="nominated_org_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.nominated_organization')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="formData.nominated_org_id"
                    :options="nominatedPrivateList"
                    id="nominated_org_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col v-if="formData.org_type_id === 1" xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Nominated Organization"  vid="nominated_org_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="nominated_org_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.nominated_organization')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                    plain
                    v-model="formData.nominated_org_id"
                    :options="nominatedMocList"
                    id="nominated_org_id"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Payment Type"  vid="payment_type_id" rules="required|min_value:1">
              <b-form-group
                  class="row"
                  label-cols-sm="12"
                  label-for="payment_type_id"
                  slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.payment_type')}} <span class="text-danger">*</span>
                </template>
                <b-form-select
                  plain
                  :disabled="formData.id?true:false"
                  v-model="formData.payment_type_id"
                  :options="paymentTypeList"
                  id="payment_type_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Amount" vid="amount" rules="required">
              <b-form-group
                class="row"
                label-cols-sm="12"
                label-for="amount"
                slot-scope="{ valid, errors }"
              >
                <template v-slot:label>
                  {{$t('elearning_iabm.amount')}} <span class="text-danger">*</span>
                </template>
                <b-form-input
                    id="amount"
                    type="number"
                    v-model="formData.amount"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col v-if="formData.payment_type_id === 2" xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Attachment" vid="attachment" :rules="formData.id ? '' : 'required'">
              <b-form-group
                class="row"
                label-cols-sm="12"
                :label="$t('globalTrans.attchment')"
                label-for="attachment"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.attachment')}} <span class="text-danger">*</span>
                </template>
                <b-form-file
                    id="attachment"
                    @change="onChangeFile"
                    v-model="formData.attachment"
                    :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-file>
                <div class="invalid-feedback">
                {{ errors[0] }}
                </div>
                <p class="text-danger">{{ $t('elearning_iabm.file_validation_size') }}</p>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { nominatedOrgPaymentStore, circularPublicationDataList, circularPublicationData, nominatedOrgPaymentUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      circularLoading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      formData: {
        fiscal_year_id: 0,
        circular_memo_no: '',
        org_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        org_type_id: 0,
        nominated_org_id: 0,
        payment_type_id: 0,
        amount: '',
        attachment: []
      },
      circularList: [],
      trainingTitleList: []
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getMeasurementUnit()
      this.formData = tmp
      this.formData.oldfile = tmp.attachment
    }
  },
  watch: {
    'formData.fiscal_year_id': function (newValue) {
      this.getCircularList()
    },
    'formData.circular_memo_no': function (newValue) {
      this.getCircularMemoNo(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    orgList () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    orgTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'এমওসি' : 'MOC',
          text_en: 'MOC',
          text_bn: 'এমওসি'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'ব্যক্তিগত' : 'Private',
          text_en: 'Private',
          text_bn: 'ব্যক্তিগত'
        }
      ]
    },
    paymentTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'ক্যাশ' : 'Cash',
          text_en: 'Cash',
          text_bn: 'ক্যাশ'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'চেক' : 'Check',
          text_en: 'Check',
          text_bn: 'চেক'
        }
      ]
    },
    nominatedMocList () {
        return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 1)
    },
    nominatedPrivateList () {
        return this.$store.state.TrainingElearning.commonObj.nominatedOrgList.filter(item => item.status === 1 && item.org_type_id === 2)
    }
  },
  methods: {
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.formData.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationDataList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
        const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
    async getCircularMemoNo (getCircularMemoNo) {
      if (getCircularMemoNo) {
        this.load = true
        const params = {
          circular_memo_no: getCircularMemoNo,
          table: 'iab_circular_publications'
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationData, params)
        if (!result.success) {
          this.formData.fiscal_year_id = 0
          this.formData.org_id = 0
          this.formData.training_title_id = 0
          this.formData.training_type_id = 0
          // this.formData.sort = 2
          this.formData.details = []
          this.circularErrorMsg = 'Please Create Circular'
          this.load = false
        } else {
          this.circularErrorMsg = false
          const trData = result.data
          this.formData.fiscal_year_id = trData.fiscal_year_id
          this.formData.org_id = trData.org_id
          this.formData.training_title_id = trData.training_title_id
          this.formData.training_type_id = trData.training_type_id
          this.load = false
        }
      }
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.training_type_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.attachment = e.target.files[0]
    },
    getTrainingTypeList (id) {
      const gttl = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1 && item.org_id === id)
      return gttl
    },
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      var formData = new FormData()
      Object.keys(this.formData).map(key => {
        formData.append(key, this.formData[key])
      })
      if (this.id) {
        formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${nominatedOrgPaymentUpdate}/${this.id}`, formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, nominatedOrgPaymentStore, formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
         this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.formData.setErrors(result.errors)
      }
    },
    getMeasurementUnit () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
