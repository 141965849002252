// Circular Publication api
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularPublicationList = circularPublicationBaseUrl + 'list'
export const circularPublicationDataList = circularPublicationBaseUrl + 'circular-publication-list'
export const circularPublicationData = circularPublicationBaseUrl + 'circular-publication-data'
export const circularPublicationToggleStatus = circularPublicationBaseUrl + 'toggle-status'
export const circularPublicationStore = circularPublicationBaseUrl + 'store'
export const circularPublicationUpdate = circularPublicationBaseUrl + 'update'
export const circularPublicationDestroy = circularPublicationBaseUrl + 'destroy'
export const circularPublicationForward = circularPublicationBaseUrl + 'forward'
export const circularPublicationForwardList = circularPublicationBaseUrl + 'forward-list'
export const circularPublicationForwardComment = circularPublicationBaseUrl + 'forward-comment'
export const circularPublicationApproveReturn = circularPublicationBaseUrl + 'approve-return'
export const circularPublicationApproveList = circularPublicationBaseUrl + 'approval-list'
export const circularPublicationPublish = circularPublicationBaseUrl + 'publish'

// Booking api
export const booingBaseUrl = '/iabm/booking/'
export const bookingApplication = booingBaseUrl + 'application'
export const bookingApplicationStore = booingBaseUrl + 'store'
export const bookingApplicationCheck = booingBaseUrl + 'room-book-check'
export const bookingApplicationList = booingBaseUrl + 'list'
export const roomRentList = '/config/room-rents/list'

// Personal Info api
export const personalInfoBaseUrl = '/iabm/personal-infos/'
export const personalInfoList = personalInfoBaseUrl + 'list'
export const personalInfoStore = personalInfoBaseUrl + 'store'
export const personalInfoUpdate = personalInfoBaseUrl + 'update'
export const personalInfoShow = personalInfoBaseUrl + 'show'
export const personalInfoApprove = personalInfoBaseUrl + 'approve'
export const personalInfoReject = personalInfoBaseUrl + 'reject'

// Training Application api
export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationList = trainingApplicationBaseUrl + 'list'
export const trainingApplicationStore = trainingApplicationBaseUrl + 'store'
export const trainingApplicationUpdate = trainingApplicationBaseUrl + 'update'
export const trainingApplicationShow = trainingApplicationBaseUrl + 'show'
export const trainingApplicationApprove = trainingApplicationBaseUrl + 'approve'
export const trainingApplicationReject = trainingApplicationBaseUrl + 'reject'
export const trainingApplicationPersonalInfo = trainingApplicationBaseUrl + 'personal-info'
export const trainingApplicationCircularPublicationInfo = trainingApplicationBaseUrl + 'circular-publication-info'

export const bookingBookingCalender = booingBaseUrl + 'booking-calender'

// Request for document api
export const requestDocumentList = '/iabm/request-for-document/list'
export const requestDocumentStore = '/iabm/request-for-document/store'
export const requestDocumentUpdate = '/iabm/request-for-document/update'
export const requestDocumentToggleStatus = '/iabm/request-for-document/toggle-status'
export const personalInfo = '/iabm/personal-infos/personal/'

// Request for complain api
export const complainList = '/iabm/complain-recommendation/list'
export const complainStore = '/iabm/complain-recommendation/store'
export const complainUpdate = '/iabm/complain-recommendation/update'
export const complainToggleStatus = '/iabm/complain-recommendation/toggle-status'
export const personalInfoAdmin = '/iabm/personal-infos/personal-admin/'

// Circular Memo List api
export const trainingPublicationBaseUrl = '/iabm/circular-publications/'
export const circularMemoList = trainingPublicationBaseUrl + 'circular-publication-list'

// Nominated Org Payment api
export const nominatedOrgPaymentList = '/iabm/nominated-org-payment/list'
export const nominatedOrgPaymentStore = '/iabm/nominated-org-payment/store'
export const nominatedOrgPaymentUpdate = '/iabm/nominated-org-payment/update'
export const nominatedOrgPaymentToggleStatus = '/iabm/nominated-org-payment/toggle-status'
